import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { VgAPI } from 'videogular2/compiled/core';
import { InfoService } from './info.service';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  public formData: FormGroup;
  public questions: any = [];
  public isSubmitted = false;
  public project_id: string;
  public language: any;
  public suggestionList: any = [];


  formatter = (x: string) => x;
  search = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),

    map(term => term === '' ? []
      : this.suggestionList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

  constructor(private fb: FormBuilder, private infoservice: InfoService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.project_id = this.route.snapshot.queryParamMap.get('project_id');
    this.language = this.route.snapshot.queryParamMap.get('lang');
    this.covidForm();
    this.getFormData();
    this.translate();
  }


  getFormData() {
    if (this.project_id && this.language) {
      this.infoservice.getCovidNormData(this.project_id, this.language).subscribe(res => {
        if (res.success == 1) {
          this.questions = res.data;
          console.log(this.questions);

          this.questions.question_data.forEach((element, index) => {
            let test;
            if(element.int_or_txt == 0 || element.int_or_txt == 1){
              test = new FormControl('', Validators.required);
            }else{
              test = new FormControl(false);
            }
            this.formData.addControl('ques' + index, test);
          });

          this.questions.suggestion_list.forEach(element => {
            this.suggestionList.push(element.company_name);
          });
          console.log(this.suggestionList);
        }
        else {
          this.router.navigate(['**']);
        }
      }, error => {
        this.router.navigate(['**']);
      })
    }
    else {
      this.router.navigate(['**']);
    }

  }

  public nameValidation;
  public mobileNoValidation;
  public companyValidation;

  public namePlaceholder;
  public mobileNoPlaceholder;
  public companyPlaceholder;

  translate() {
    if (this.language == 1) {
      this.nameValidation = "Name is required.";
      this.mobileNoValidation = "Contact number is required.";
      this.companyValidation = "Company name is required.";

      this.namePlaceholder = "Enter Name";
      this.mobileNoPlaceholder = "Enter Contact Number";
      this.companyPlaceholder = "Enter Company Name";
    }
    else {
      this.nameValidation = "Se requiere el nombre.";
      this.mobileNoValidation = "Se requiere número de contacto.";
      this.companyValidation = "El nombre de la empresa es obligatorio.";

      this.namePlaceholder = "Ingrese su nombre";
      this.mobileNoPlaceholder = "Ingrese el número de contacto";
      this.companyPlaceholder = "Introduzca el nombre de la empresa";

    }

  }

  covidForm() {
    this.formData = this.fb.group({
      'name': ['', Validators.compose([Validators.required])],
      // 'mobile_no': ['', Validators.compose([Validators.required])],
      'company_name': ['', Validators.compose([Validators.required])],
    });
  }

  public ques_ans: any = [];
  public yesCount: any;
  public safe;

  onSubmit() {
    console.log(this.formData.value);
    
    $('.btn-primary').prop('disabled', true);
    this.yesCount = 0;
    this.ques_ans = [];

    this.questions.question_data.forEach((element, index) => {
      element.ans_opt.forEach(ele => {
        if (element.int_or_txt == 0) {
          if (ele.id == this.formData.controls['ques' + index].value) {
            this.ques_ans.push({ "ques_id": element.id, "ans_id": ele.id });
            if (ele.ans != 'No') {
              this.yesCount++;
            }
          }
        }
        else{

        }
      });
      if (element.int_or_txt == 2) {
        if (this.formData.controls['ques' + index].value == true) {
          this.ques_ans.push({ "ques_id": element.id, "ans_id": element.ans_opt[0].id});
        }
        else{
          this.ques_ans.push({ "ques_id": element.id, "ans_id": element.ans_opt[1].id});
        }
      }
    });
    let is_symptomatic = "No";
    if (this.yesCount >= 1) {
      is_symptomatic = "Yes";
    }

    let data = {
      "name": this.formData.controls['name'].value,
      "mobile_no": "",
      "sub_contractor_name": this.formData.controls['company_name'].value,
      "project_id": this.project_id,
      "ques_ans": this.ques_ans,
      "lang": this.language,
      "is_symptomatic": is_symptomatic
    }

    console.log(data);
    
    this.infoservice.submitData(data).subscribe(res => {
      if (this.yesCount >= 1) {
        console.log('Unsafe', this.yesCount);
        this.isSubmitted = true;
        this.safe = false;
      }
      else {
        console.log('Safe', this.yesCount);
        this.isSubmitted = true;
        this.safe = true;
      }
    })
  }
}
