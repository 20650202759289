import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InfoService {

  constructor(private http: HttpClient) {}


  getCovidNormData(p_id,lan){
    return  this.http.post<any>(environment.apiUrl+'/get_questions',
    {
      Project_id:p_id,
      lang:lan
    }); 
  }

  submitData(data){
    return  this.http.post<any>(environment.apiUrl+'/submit_answers',data)
  }

  public getCategoryDetails(catIdObj:any) {
    return this.http.post<any>(environment.apiUrl + '/get_dy_question_list_by_cat_id',catIdObj)
  }

  
  public submitCategoryDetails(catIdObj:any) {
    return this.http.post<any>(environment.apiUrl + '/submit_dy_answers',catIdObj)
  }

  public getAssignCompnayList(Obj) {
    return this.http.post<any>(environment.apiUrl + '/company_list_with_assignee', Obj)
  }



}
