import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from "jquery";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'skiles-steper';

  // test

  // constructor(private router: Router,private route: ActivatedRoute) {
  //   this.router.navigate(['/covid'], { queryParams: { project_id: 1, lang: 1 } });
  // }
  
}
