import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule , ReactiveFormsModule } from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import { NgxTypeaheadModule } from 'ngx-typeahead';  
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { AppRoutingModule, routing } from './app-routing.module';
import { AppComponent } from './app.component';
import { VgCoreModule } from 'videogular2/compiled/core';
import { VgControlsModule } from 'videogular2/compiled/controls';
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { AttachmentComponent } from './attachment/attachment.component';
import { Attachment2Component } from './attachment2/attachment2.component';
import { SignupComponent } from './signup/signup.component';
import { TestComponent } from './test/test.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgSelectConfig, ɵs } from '@ng-select/ng-select';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    InfoComponent,
    NotfoundComponent,
    AttachmentComponent,
    Attachment2Component,
    SignupComponent,
    TestComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    VgCoreModule,
    VgControlsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    routing,
    NgxTypeaheadModule,
    NgbModule,
    NgxSkeletonLoaderModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgSelectModule
  ],
  providers: [NgSelectConfig, ɵs],
  bootstrap: [AppComponent]
})
export class AppModule { }
