import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { VgAPI } from 'videogular2/compiled/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { InfoService } from '../info/info.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {

  public formData: FormGroup;
  public engQuestionAnsForm: FormGroup;
  public spnQuestionAnsForm: FormGroup;
  public questionAnsForm: FormGroup;
  public questionData: any = [];
  public isSubmitted = false;
  public project_id: string;
  public language: any;
  public suggestionList: any = [];
  public categoryDetails: any;
  public category_id: string;


  formatter = (x: string) => x;
  search = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),

    map(term => term === '' ? []
      : this.suggestionList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )


  selectedOptionControl: AbstractControl | any;
  optionCtrl: AbstractControl | any;

  constructor(
    private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private infoservice: InfoService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.project_id = this.route.snapshot.queryParamMap.get('project_id');
    this.language = this.route.snapshot.queryParamMap.get('lang');
    this.category_id = this.route.snapshot.queryParamMap.get('cat_id');
    console.log("category ID=>>", this.category_id);
    this.covidForm();
    this.getFormData();
    this.translate();
    this.getCategoryData();

  }

  public getFormData() {
    if (this.project_id && this.language) {
      this.infoservice.getCovidNormData(this.project_id, this.language).subscribe(res => {
        if (res.success == 1) {
          this.questionData = res.data;
          this.questionData.suggestion_list.forEach(element => {
            this.suggestionList.push(element.company_name);
          });
          console.log(this.suggestionList);
        }
        else {
          this.router.navigate(['**']);
        }
      }, error => {
        this.router.navigate(['**']);
      })
    }
    else {
      this.router.navigate(['**']);
    }

  }

  catNoteHTML: string = '';
  quesAns: any;
  public getCategoryData() {
    let cateObj =
    {
      cat_id: this.category_id,
      lang_id: this.language
    }
    this.infoservice.getCategoryDetails(cateObj).subscribe(res => {
      if (res.success == 1) {
        this.categoryDetails = res.data
        this.catNoteHTML = this.categoryDetails.cat_note;
        console.log("this.categoryDetails", this.categoryDetails);

        this.quesAns = this.categoryDetails.quesAns;
        console.log("this.quesAns", this.quesAns);

        this.buildForm();
      }
      else { }

    })
  }

  buildForm(): void {
    this.quesAns.forEach(question => {
      if (question.type === 'text') {
        this.formData.addControl('question_' + question.ques_id, this.fb.control('', Validators.required));
      } else if (question.type === 'radio') {
        this.formData.addControl('question_' + question.ques_id, this.fb.control('', Validators.required));
      }
    });
  }



  public nameValidation;
  public mobileNoValidation;
  public companyValidation;
  public namePlaceholder;
  public mobileNoPlaceholder;
  public companyPlaceholder;

  public translate() {
    if (this.language == 1) {
      this.nameValidation = "Name is required.";
      this.mobileNoValidation = "Contact number is required.";
      this.companyValidation = "Company name is required.";

      this.namePlaceholder = "Enter Name";
      this.mobileNoPlaceholder = "Enter Contact Number";
      this.companyPlaceholder = "Enter Company Name";
    }
    else {
      this.nameValidation = "Se requiere el nombre.";
      this.mobileNoValidation = "Se requiere número de contacto.";
      this.companyValidation = "El nombre de la empresa es obligatorio.";

      this.namePlaceholder = "Ingrese su nombre";
      this.mobileNoPlaceholder = "Ingrese el número de contacto";
      this.companyPlaceholder = "Introduzca el nombre de la empresa";

    }

  }

  public covidForm() {
    this.formData = this.fb.group({
      'name': ['', Validators.compose([Validators.required])],
      'company_name': ['', Validators.compose([Validators.required])],
    });
  }

  public ques_ans: any = [];
  public yesCount: any;
  public safe;

  public onSubmit() {
    if (this.formData.valid) {


      const formattedData = [];

      this.quesAns.forEach(question => {
        const answer = this.formData.value['question_' + question.ques_id];
        let ans_id = null;
        if (question.type === 'radio' && question.Options) {
          const selectedOption = question.Options.find(option => option.option === answer);
          ans_id = selectedOption ? selectedOption.ans_id : null;
        }

        formattedData.push({
          ques_id: question.ques_id,
          type: question.type,
          ans_id: ans_id,
          answer: question.type === 'text' ? answer : null
        });
      });
      console.log("formattedData->",formattedData);


      console.log(this.formData.value);
      $('.btn-primary').prop('disabled', false);
      let submitData = {
        name: this.formData.controls['name'].value,
        // mobile_no: "",
        sub_contractor_name: this.formData.controls['company_name'].value,
        project_id: this.project_id,
        ques_ans: formattedData,
        lang: this.language,
        cat_id: this.category_id,
      }
      console.log("submitData->",submitData);

    }
    else {
      $('.btn-primary').prop('disabled', true);
    }
  }
}
