import { ModuleWithProviders, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AttachmentComponent } from './attachment/attachment.component';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './home/home.component';
import { InfoComponent } from './info/info.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { SignupComponent } from './signup/signup.component';
import { TestComponent } from './test/test.component';

const routes: Routes = [
  {path: '', redirectTo: '/covid', pathMatch: 'full'},
  {path: 'covid', component: InfoComponent},
  {path: 'covid-info', component: HomeComponent},
  {path: 'category', component: SignupComponent},
  // {path: 'category', component:TestComponent},
  {path: 'home_isolation-info', component: AttachmentComponent},
  {path: '**', redirectTo: '/404'},
  {path: '404', component: NotfoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,  // <- uncomment this line for disable lazy load
  useHash:true
});
