import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) {}

  getCovidNormData(p_id,lan){
    return  this.http.post<any>(environment.apiUrl+'/get_video_ques',
    {
      project_id:p_id,
      lang:lan
    }); 
  }

  submitData(data:any){
    return  this.http.post<any>(environment.apiUrl+'/submit_video_ans',data); 
  }

  
}
