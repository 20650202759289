import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { environment } from 'src/environments/environment';
import { VgAPI } from 'videogular2/compiled/core';
import { HomeService } from './home.service';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public formData: FormGroup;
  public isNext = false;
  public ques_ans: any = [];
  public yesCount: any;
  public safe;
  public project_id: string;
  public language: any = 1;
  public isSubmitted = false;
  public questions: any = [];
  public items: FormArray;
  public videoData: any;
  public successMsg: any;
  public isCompleted = false;
  public isSuccess: any;
  public suggestionList: any = [];

  public nameValidation;
  public mobileNoValidation;
  public companyValidation;

  public namePlaceholder;
  public mobileNoPlaceholder;
  public companyPlaceholder;

  formatter = (x: string) => x;
  search = (text$: Observable<string>) => text$.pipe(
    debounceTime(200),

    map(term => term === '' ? []
      : this.suggestionList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

  constructor(private fb: FormBuilder, private homeservice: HomeService, private route: ActivatedRoute, private router: Router) { }

  private stepper: Stepper;

  ngOnInit() {
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    })
    this.project_id = this.route.snapshot.queryParamMap.get('project_id');
    this.language = this.route.snapshot.queryParamMap.get('lang');
    this.covidForm();
    this.getFormData();
    this.translate();
  }

  getFormData() {
    if (this.project_id && this.language) {
      this.homeservice.getCovidNormData(this.project_id, this.language).subscribe(res => {
        if (res.success == 1) {
          this.questions = res.data;
          this.videoData= this.questions.video;        
          this.videoData.forEach(element => {
            element.videoUrlLink = element.video_url;
            element.isCompleted=false;
            element.is_show=false;
          });
          this.videoData[0].is_show=true;
          
          this.questions.question_data.forEach((element, index) => {
            let test = new FormControl('', Validators.required);
            this.formData.addControl('ques' + index, test);
          });
          this.questions.suggestion_list.forEach(element => {
            this.suggestionList.push(element.company_name);
          });
        }
        else {
          this.router.navigate(['**']);
        }
      }, error => {
        this.router.navigate(['**']);
      })
    }
    else {
      this.router.navigate(['**']);
    }
  }

  covidForm() {
    this.formData = this.fb.group({
      'name': ['', Validators.compose([Validators.required])],
      'company_name': ['', Validators.compose([Validators.required])],
    });
  }

  createItem(): FormGroup {
    return this.fb.group({
      ques_id: '',
      ans_id: ''
    });
  }

  translate() {
    if (this.language == 1) {
      this.nameValidation = "Name is required.";
      this.mobileNoValidation = "Contact number is required.";
      this.companyValidation = "Company name is required.";

      this.namePlaceholder = "Enter Name";
      this.mobileNoPlaceholder = "Enter Contact Number";
      this.companyPlaceholder = "Enter Company Name";
    }
    else {
      this.nameValidation = "Se requiere el nombre.";
      this.mobileNoValidation = "Se requiere número de contacto.";
      this.companyValidation = "El nombre de la empresa es obligatorio.";

      this.namePlaceholder = "Ingrese su nombre";
      this.mobileNoPlaceholder = "Ingrese el número de contacto";
      this.companyPlaceholder = "Introduzca el nombre de la empresa";

    }

  }


  nextFormInfo(event) {
    this.formData.markAllAsTouched();
    if (this.formData.controls['name'].valid && this.formData.controls['company_name'].valid) {
      this.next(event);
    }
  }

  onSubmit(event) {
    $('.btn-primary').prop('disabled', true);
    this.yesCount = 0;
    this.ques_ans = [];

    this.questions.question_data.forEach((element, index) => {
      element.ans_opt.forEach(ele => {
        if (element.int_or_txt == 0) {
          if (ele.id == this.formData.controls['ques' + index].value) {
            this.ques_ans.push({ "ques_id": element.id, "ans_id": ele.id });
            if (ele.ans != 'No') {
              this.yesCount++;
            }
          }
        }
        else {

        }
      });
      if (element.int_or_txt == 2) {
        if (this.formData.controls['ques' + index].value == true) {
          this.ques_ans.push({ "ques_id": element.id, "ans_id": element.ans_opt[0].id });
        }
        else {
          this.ques_ans.push({ "ques_id": element.id, "ans_id": element.ans_opt[1].id });
        }
      }
    });
    // let is_symptomatic = "No";
    // if (this.yesCount >= 1) {
    //   is_symptomatic = "Yes";
    // }

    let data = {
      "name": this.formData.controls['name'].value,
      "company_name": this.formData.controls['company_name'].value,
      "ques_ans": this.ques_ans,
      "project_id": this.project_id,
      "lang": this.language,
    }

    this.homeservice.submitData(data).subscribe(res => {
      this.successMsg = res.message;
      if (res.success == 1) {
        this.isSuccess = true;
        // if (this.yesCount >= 1) {
        //   console.log('Unsafe', this.yesCount);
        //   this.isSubmitted = true;
        //   this.safe = false;
        // }
        // else {
        //   console.log('Safe', this.yesCount);
        //   this.isSubmitted = true;
        //   this.safe = true;
        // }
      }
      else {
        this.isSuccess = false;
      }
      this.next(event);
      return false;
    })
  }

  public api;
  onPlayerReady(api: VgAPI,video:any) {
    this.api = api;
    // this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe(
    //    this.playVideo.bind(this)
    // );
    this.api.getDefaultMedia().subscriptions.ended.subscribe(
      this.nextVideo.bind(this,video)
    );
  }

  nextVideo(video:any) {
    video.isCompleted = true;  
    // if (this.api.currentTime == this.api.duration) {
      //this.isCompleted = true;
    // }
  }
  
  nextVideoPlay(event:any,video:any,index){
    if(this.videoData.length-1 != index){
      video.is_show=false;
      this.videoData[index+1].is_show=true;
    }
    let is_notview=0;
    this.videoData.forEach(element => {
      if(element.isCompleted == false){
        is_notview++;
      }
    });
    if(is_notview == 0){
      this.next(event);
    }
  }

  next(event) {
    this.stepper.next();
    event.preventDefault();
    event.stopPropagation();
  }

}
