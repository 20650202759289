import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Stepper from 'bs-stepper';
import { VgAPI } from 'videogular2/compiled/core';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { InfoService } from '../info/info.service';

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

	public formData: FormGroup;
	public engQuestionAnsForm: FormGroup;
	public spnQuestionAnsForm: FormGroup;
	public questionAnsForm: FormGroup;
	public questionData: any = [];
	public isSubmitted = false;
	public project_id: string;
	public language: any;
	public suggestionList: any = [];
	public categoryDetails: any;
	public category_id: string;


	formatter = (x: string) => x;
	search = (text$: Observable<string>) => text$.pipe(
		debounceTime(200),

		map(term => term === '' ? []
			: this.suggestionList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
	)


	selectedOptionControl: AbstractControl | any;
	optionCtrl: AbstractControl | any;

	constructor(
		private fb: FormBuilder,
		private formBuilder: FormBuilder,
		private infoservice: InfoService,
		private router: Router,
		private route: ActivatedRoute) { }

	ngOnInit() {
		this.project_id = this.route.snapshot.queryParamMap.get('project_id');
		this.language = this.route.snapshot.queryParamMap.get('lang');
		this.category_id = this.route.snapshot.queryParamMap.get('cat_id');
		console.log("category ID=>>", this.category_id);
		this.covidForm();
		this.getFormData();
		this.translate();
		this.getCategoryData();
		this.getCompanyData()

		this.questionAnsForm = this.fb.group({
			questions: this.fb.array([])
		});



	}

	companyName: any[] = []
	public getFormData() {
		if (this.project_id && this.language) {
			this.infoservice.getCovidNormData(this.project_id, this.language).subscribe(res => {
				if (res.success == 1) {
					this.questionData = res.data;
					this.companyName = this.questionData.suggestion_list
					this.questionData.suggestion_list.forEach(element => {
						this.suggestionList.push(element.company_name);
					});
					console.log(this.suggestionList);
				}
				else {
					this.router.navigate(['**']);
				}
			}, error => {
				this.router.navigate(['**']);
			})
		}
		else {
			this.router.navigate(['**']);
		}

	}

	get questions(): FormArray {
		return this.questionAnsForm.get('questions') as FormArray;
	}

	getCompanyNameData: any[] = []
	filteredCompanyData: any[] = [];
	getCompanyData() {
		let companyObj = {
			project_id: this.project_id
		}
		this.infoservice.getAssignCompnayList(companyObj).subscribe(res => {
			if (res.success == 1) {
				console.log("res.data", res.data);

				this.getCompanyNameData = res.data;
				this.filteredCompanyData = this.getCompanyNameData;
				// this.filteredCompanyData = this.getCompanyNameData.filter(company =>
				//   company.company_name.toLowerCase().includes(this.newCompanyName.toLowerCase())
				// );

				this.filteredCompanyData = this.getCompanyNameData.filter(company =>
					company.checked === true
				);
	

			} else {
			}
		})
	}

	//   filterCompanyData() {
	//     this.filteredCompanyData = this.getCompanyNameData.filter(company =>
	//       company.company_name.toLowerCase().includes(this.newCompanyName.toLowerCase())
	//     );
	//   }


	catNoteHTML: string = '';
	quesAns: any;
	public getCategoryData() {
		let cateObj =
		{
			cat_id: this.category_id,
			lang_id: this.language
		}
		this.infoservice.getCategoryDetails(cateObj).subscribe(res => {
			if (res.success == 1) {
				this.categoryDetails = res.data
				this.catNoteHTML = this.categoryDetails.cat_note;
				console.log("this.categoryDetails", this.categoryDetails);

				if (this.categoryDetails.is_que == 0) {
					console.log("in 0");
				}
				else {
					console.log("in 1");
					this.quesAns = this.categoryDetails.quesAns;
					console.log("this.quesAns", this.quesAns);
					this.patchFormArrayValues(this.quesAns);
				}
			}
			else { }
		})
	}

	public patchFormArrayValues(quesAns: any[]) {
		const questionFormArray = this.questionAnsForm.get('questions') as FormArray;
		questionFormArray.clear();
		quesAns.forEach(question => {
			const questionGroup = this.fb.group({
				ques_id: [question.ques_id],
				que: [question.que, Validators.required],
				type: [question.type],
				ans_id: [question.ans_id],
				answer: ['', Validators.required]
			});
			if (question.type === 'radio') {
				const optionsArray = this.fb.array([]);
				question.Options.forEach(option => {
					optionsArray.push(this.fb.group({
						options: [option.option, Validators.required],
						ans_id: [option.ans_id]
					}));
				});
				questionGroup.addControl('options', optionsArray);
			}
			questionFormArray.push(questionGroup);
		});
	}

	public updateSelectedOption(questionIndex: number, optionIndex: number, event: any, value: any): void {
		const questionsArray = this.questionAnsForm.get('questions') as FormArray;
		const selectedOption = (questionsArray.at(questionIndex).get('options') as FormArray)
			.at(optionIndex).get('ans_id').value;
		questionsArray.at(questionIndex).get('ans_id').setValue(selectedOption);
	}

	public prepareDataForBackend(): any[] {
		return this.questionAnsForm.value.questions.map((question: any) => ({
			ques_id: question.ques_id,
			type: question.type,
			ans_id: question.ans_id,
			answer: question.type === 'text' ? question.answer : null
		}));
	}

	public isFormDataComplete(): boolean {
		const questionsArray = this.questionAnsForm.get('questions') as FormArray;

		for (let i = 0; i < questionsArray.length; i++) {
			const questionGroup = questionsArray.at(i) as FormGroup;
			const type = questionGroup.get('type').value;

			if (type === 'text') {
				const answer = questionGroup.get('answer').value;
				if (!answer) {
					return false; // Return false if any text answer is empty
				}
			} else if (type === 'radio') {
				const ansId = questionGroup.get('ans_id').value;
				if (!ansId) {
					return false; // Return false if ans_id is not selected for any radio question
				}
			}
		}

		return true; // Return true if all conditions are met
	}

	public nameValidation;
	public mobileNoValidation;
	public companyValidation;

	public namePlaceholder;
	public mobileNoPlaceholder;
	public companyPlaceholder;

	public translate() {
		if (this.language == 1) {
			this.nameValidation = "Name is required.";
			this.mobileNoValidation = "Contact number is required.";
			this.companyValidation = "Company name is required.";

			this.namePlaceholder = "Enter Name";
			this.mobileNoPlaceholder = "Enter Contact Number";
			this.companyPlaceholder = "Enter Company Name";
		}
		else {
			this.nameValidation = "Se requiere el nombre.";
			this.mobileNoValidation = "Se requiere número de contacto.";
			this.companyValidation = "El nombre de la empresa es obligatorio.";

			this.namePlaceholder = "Ingrese su nombre";
			this.mobileNoPlaceholder = "Ingrese el número de contacto";
			this.companyPlaceholder = "Introduzca el nombre de la empresa";

		}

	}

	public covidForm() {
		this.formData = this.fb.group({
			'name': ['', Validators.compose([Validators.required])],
			'company_name': ['', Validators.compose([Validators.required])],
		});
	}

	public ques_ans: any = [];
	public yesCount: any;
	public safe;

	public onSubmit() {
		if (this.formData.valid) {

			console.log(this.formData.value);
			$('.btn-primary').prop('disabled', false);
			const quesAnsData = this.prepareDataForBackend();
			let submitData = {
				name: this.formData.controls['name'].value,
				// mobile_no: "",
				sub_contractor_name: this.formData.controls['company_name'].value,
				project_id: this.project_id,
				ques_ans: quesAnsData,
				lang: this.language,
				cat_id: this.category_id,
			}
			console.log(submitData);
			this.infoservice.submitCategoryDetails(submitData).subscribe(res => {
				if (res.success == 1) {
					this.isSubmitted = true;
					this.safe = true;
				}
				else {
					console.log('Safe', this.yesCount);
					this.isSubmitted = true;
					this.safe = false;
				}
			})
		}
		else {
			$('.btn-primary').prop('disabled', true);
		}
	}
}
